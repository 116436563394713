import {
  ApolloClient,
  ApolloLink,
  createQueryPreloader,
  HttpLink,
  NormalizedCacheObject,
  PreloadQueryFunction,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { fetchAuthSession } from 'aws-amplify/auth';
import { AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { API_KEY, AWS_GRAPHQL_API_URL, AWS_REGION } from '../env.ts';
import { traceError } from '@/utils/trace-error.ts';
import { getAuthorizedData } from '../routes-utils/handle-authorized-data.ts';
import { makeApolloCache } from './apollo-cache.ts';
import { GraphQLError } from 'graphql';

const auth = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  async jwtToken() {
    const session = await fetchAuthSession();

    return session.tokens?.accessToken.toString() || '';
  },
} satisfies AuthOptions;

const url = AWS_GRAPHQL_API_URL;
const region = AWS_REGION;

const httpLink = new HttpLink({
  uri: (operation) => `${url}?${operation.operationName}`,
});

const authLink = setContext(async (_, { headers, authType }) => {
  const { tokens } = await getAuthorizedData();
  // Add the API key only for specific queries
  if (!tokens && authType === 'API_KEY') {
    return {
      headers: {
        ...headers,
        'x-api-key': API_KEY,
      },
    };
  }

  if (tokens) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${tokens.accessToken}`,
      },
    };
  }

  return {
    headers: headers,
  };
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) =>
      traceError(new Error('[GraphQL error]: ' + message)),
    );
  if (networkError) {
    if (networkError.name === 'AbortError') {
      return;
    }

    if ('errors' in networkError) {
      // aws-appsync-subscription-link places all errors under networkError.errors , e.g. https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/659
      (networkError.errors as GraphQLError[]).forEach((err) =>
        traceError(
          new Error('[GraphQL Subscription error]: ' + err.message, {
            cause: err,
          }),
        ),
      );
      return;
    }

    traceError(
      new Error('[GraphQL network error]: ' + networkError.message, {
        cause: networkError,
      }),
    );
    if ('statusCode' in networkError && networkError.statusCode === 401) {
      // if (router.state.location.pathname !== paths.auth) {
      //   router.navigate(
      //     getRedirectLink(paths.auth, router.state.location.pathname),
      //   );
      // }
    }
  }
});

let apolloClient: ApolloClient<NormalizedCacheObject>;

export const getApolloClient = () => {
  if (!apolloClient) {
    apolloClient = new ApolloClient({
      cache: makeApolloCache(),
      link: ApolloLink.from([
        errorLink,
        authLink,
        createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
      ]),
    });
  }
  return apolloClient;
};

let queryPreloader: PreloadQueryFunction;

export const preloadQuery: PreloadQueryFunction = (
  ...args: Parameters<PreloadQueryFunction>
) => {
  queryPreloader = queryPreloader || createQueryPreloader(apolloClient);
  return queryPreloader(...args);
};
